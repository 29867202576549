export enum OnboardingTaskType {
    firstOpen = "first_open",
    dashboard = "dashboard",
    mySailebot = "my_sailebot",
    campaignContent = "campaign_content",
    campaignCompanies = "campaign_companies",
    campaignExcludedCompanies = "campaign_excluded_companies",
    campaignContacts = "campaign_contacts",
    campaignUnsubscribedContacts = "campaign_unsubscribed_contacts",
    campaignAiqls = "campaign_aiqls",
    campaignPositiveResponses = "campaign_positive_responses",
    campaignDeveloping = "campaign_developing",
    campaignObjections = "campaign_objections",
    campaignTargeting = "campaign_targeting",
    contentUpdates = "content_updates",
    mergeFieldExplanation = "merge_field_explanation",
}

export namespace OnboardingTaskType {
    export const hasPreviews = [
        OnboardingTaskType.dashboard,
        OnboardingTaskType.mySailebot,
        OnboardingTaskType.campaignContent,
        OnboardingTaskType.campaignCompanies,
        OnboardingTaskType.campaignExcludedCompanies,
        OnboardingTaskType.campaignContacts,
        OnboardingTaskType.campaignUnsubscribedContacts,
        OnboardingTaskType.campaignAiqls,
        OnboardingTaskType.campaignPositiveResponses,
        OnboardingTaskType.campaignDeveloping,
        OnboardingTaskType.campaignObjections,
        OnboardingTaskType.campaignTargeting,
    ];
}
